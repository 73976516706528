import axios from 'axios';
import store from '@/store';
import methods from '@/helpers/methods';
import localize from '@/filters/localize';
import adminApi from '@/api/adminApi';
import customerApi from '@/api/customerApi';
import transportCompanyApi from '@/api/transportCompanyApi';
import authorizationApi from '@/api/authorizationApi';

// Configure axios to handle nested objects like in axios 0.27.2 (previous)
axios.defaults.paramsSerializer = {
  serialize: params => {
    // Custom serializer for nested objects
    return Object.keys(params).map(key => {
      const value = params[key];
      if (value !== null && typeof value === 'object') {
        // JSON stringify objects (like in axios 0.27.2 (previous))
        return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`;
      }
      // Handle primitive values normally
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }).join('&');
  }
};

axios.interceptors.request.use(
  (config) => {
    const token = store.getters.token;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      methods.showGlobalSnackbar('primary', localize('tokenNote'));
      methods.logOut();
    }
    return Promise.reject(error);
  }
);

export default {
  ...customerApi,
  ...transportCompanyApi,
  ...adminApi,
  ...authorizationApi,
};
