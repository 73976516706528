import Vue from 'vue';
import Vuex from 'vuex';
import localize from '@/filters/localize';
import {PRODUCT_TYPES} from '@/enums/productTypes';
import {GOOD_TYPES} from '@/enums/goodTypes';
import {TRANSPORT_TYPES} from '@/enums/transportMode';
import {ROLES} from '@/enums/roles';
import {SPECIAL_REQUIREMENTS_TYPES} from '@/enums/specialRequirements';
import {PERSONALIZATION} from '@/enums/personalization';
import {REQUEST_TYPES} from '@/enums/requestType';
import {DELIVERY_TERMS} from '@/enums/deliveryTerms';
import {STATUSES} from '@/enums/statuses';
import { PRESETS } from '@/enums/presets';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: 'se',
    apiLocales: {},
    apiSettings: {},
    userData: null,
    globalSnackbar: false,
    globalSnackbarColor: 'error',
    globalSnackbarText: '',
  },
  getters: {
    locale(state) {
      return state.locale;
    },
    apiLocales(state) {
      return state.apiLocales;
    },
    apiSettings(state) {
      return state.apiSettings;
    },
    token(state) {
      return state.userData && state.userData['access_token'];
    },
    userData(state) {
      return state.userData;
    },
    isAdmin(state) {
      return state.userData && state.userData.role === ROLES.ADMIN;
    },
    isCompany(state) {
      return state.userData && state.userData.role === ROLES.COMPANY;
    },
    isCustomer(state) {
      return state.userData && state.userData.role === ROLES.CLIENT;
    },
    globalSnackbar(state) {
      return state.globalSnackbar;
    },
    globalSnackbarColor(state) {
      return state.globalSnackbarColor;
    },
    globalSnackbarText(state) {
      return state.globalSnackbarText;
    },
    productTypes() {
      return [
        {key: PRODUCT_TYPES.BOOKS, value: localize(PRODUCT_TYPES.BOOKS)},
        {key: PRODUCT_TYPES.BUILDING_MATERIALS, value: localize(PRODUCT_TYPES.BUILDING_MATERIALS)},
        {key: PRODUCT_TYPES.CLOTHING, value: localize(PRODUCT_TYPES.CLOTHING)},
        {key: PRODUCT_TYPES.EATABLES, value: localize(PRODUCT_TYPES.EATABLES)},
        {key: PRODUCT_TYPES.ELECTRONIC_GOODS, value: localize(PRODUCT_TYPES.ELECTRONIC_GOODS)},
        {key: PRODUCT_TYPES.FURNITURE, value: localize(PRODUCT_TYPES.FURNITURE)},
        {key: PRODUCT_TYPES.HOUSEHOLD_SUPPLIES, value: localize(PRODUCT_TYPES.HOUSEHOLD_SUPPLIES)},
        {key: PRODUCT_TYPES.INDUSTRIAL_GOODS, value: localize(PRODUCT_TYPES.INDUSTRIAL_GOODS)},
        {key: PRODUCT_TYPES.TOOLS, value: localize(PRODUCT_TYPES.TOOLS)},
        {key: PRODUCT_TYPES.WHITE_GOODS, value: localize(PRODUCT_TYPES.WHITE_GOODS)},
        {key: PRODUCT_TYPES.OTHER, value: localize(PRODUCT_TYPES.OTHER)},
      ];
    },
    goodTypesSorted() {
      return {
        [TRANSPORT_TYPES.ROAD]: [
          {key: GOOD_TYPES['40_HC'], value: GOOD_TYPES['40_HC_VALUE']},
          {key: GOOD_TYPES['40'], value: GOOD_TYPES['40_VALUE']},
          {key: GOOD_TYPES['20'], value: GOOD_TYPES['20_VALUE']},
          {key: GOOD_TYPES.FTL, value: GOOD_TYPES.FTL_VALUE},
          {key: GOOD_TYPES.PALLET, value: localize(GOOD_TYPES.PALLET)},
          {key: GOOD_TYPES.PACKAGE, value: localize(GOOD_TYPES.PACKAGE)}
        ],
        [TRANSPORT_TYPES.SEA]: [
          {key: GOOD_TYPES['40_HC'], value: GOOD_TYPES['40_HC_VALUE']},
          {key: GOOD_TYPES['40'], value: GOOD_TYPES['40_VALUE']},
          {key: GOOD_TYPES['20'], value: GOOD_TYPES['20_VALUE']},
          {key: GOOD_TYPES.PALLET, value: localize(GOOD_TYPES.PALLET)},
          {key: GOOD_TYPES.PACKAGE, value: localize(GOOD_TYPES.PACKAGE)}
        ],
        [TRANSPORT_TYPES.AIR]: [
          {key: GOOD_TYPES.PALLET, value: localize(GOOD_TYPES.PALLET)},
          {key: GOOD_TYPES.PACKAGE, value: localize(GOOD_TYPES.PACKAGE)}
        ],
        [TRANSPORT_TYPES.RAIL]: [
          {key: GOOD_TYPES['40_HC'], value: GOOD_TYPES['40_HC_VALUE']},
          {key: GOOD_TYPES['40'], value: GOOD_TYPES['40_VALUE']},
          {key: GOOD_TYPES['20'], value: GOOD_TYPES['20_VALUE']},
          {key: GOOD_TYPES.PALLET, value: localize(GOOD_TYPES.PALLET)},
          {key: GOOD_TYPES.PACKAGE, value: localize(GOOD_TYPES.PACKAGE)}
        ],
        individual: [
          {key: GOOD_TYPES.FTL, value: GOOD_TYPES.FTL_VALUE},
          {key: GOOD_TYPES.PALLET, value: localize(GOOD_TYPES.PALLET)},
          {key: GOOD_TYPES.PACKAGE, value: localize(GOOD_TYPES.PACKAGE)}
        ],
        all: [
          {key: GOOD_TYPES['40_HC'], value: GOOD_TYPES['40_HC_VALUE']},
          {key: GOOD_TYPES['40'], value: GOOD_TYPES['40_VALUE']},
          {key: GOOD_TYPES['20'], value: GOOD_TYPES['20_VALUE']},
          {key: GOOD_TYPES.FTL, value: GOOD_TYPES.FTL_VALUE},
          {key: GOOD_TYPES.PALLET, value: localize(GOOD_TYPES.PALLET)},
          {key: GOOD_TYPES.PACKAGE, value: localize(GOOD_TYPES.PACKAGE)}
        ]
      };
    },
    transportModeList() {
      return [
        {name: localize(TRANSPORT_TYPES.SEA), value: TRANSPORT_TYPES.SEA},
        {name: localize(TRANSPORT_TYPES.ROAD), value: TRANSPORT_TYPES.ROAD},
        {name: localize(TRANSPORT_TYPES.AIR), value: TRANSPORT_TYPES.AIR},
        {name: localize(TRANSPORT_TYPES.RAIL), value: TRANSPORT_TYPES.RAIL}
      ];
    },
    requirementsList() {
      return [
        {key: SPECIAL_REQUIREMENTS_TYPES.STANDARD, value: localize(SPECIAL_REQUIREMENTS_TYPES.STANDARD)},
        {key: SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS, value: localize(SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS)},
        {key: SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS, value: localize(SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS)}
      ];
    },
    receiverList() {
      return [
        {key: PERSONALIZATION.COMPANY, value: localize(PERSONALIZATION.COMPANY)},
        {key: PERSONALIZATION.INDIVIDUAL, value: localize(PERSONALIZATION.INDIVIDUAL)}
      ];
    },
    requestTypesList() {
      return [
        {
          name: localize(REQUEST_TYPES.PRICE_REQUEST),
          value: REQUEST_TYPES.PRICE_REQUEST
        },
        {
          name: localize(REQUEST_TYPES.READY_FOR_COLLECTION),
          value: REQUEST_TYPES.READY_FOR_COLLECTION
        },
      ];
    },
    relevanciesSetList() {
      return [
        {key: 'yes', value: localize('yes')},
        {key: 'no', value: localize('no')},
      ];
    },
    deliveryTerms() {
      return [
        {text: localize(DELIVERY_TERMS.EXW), value: DELIVERY_TERMS.EXW},
        {text: localize(DELIVERY_TERMS.FCA), value: DELIVERY_TERMS.FCA},
        {text: localize(DELIVERY_TERMS.FAS), value: DELIVERY_TERMS.FAS},
        {text: localize(DELIVERY_TERMS.FOB), value: DELIVERY_TERMS.FOB},
        {text: localize(DELIVERY_TERMS.CFR), value: DELIVERY_TERMS.CFR},
        {text: localize(DELIVERY_TERMS.CIF), value: DELIVERY_TERMS.CIF},
        {text: localize(DELIVERY_TERMS.CPT), value: DELIVERY_TERMS.CPT},
        {text: localize(DELIVERY_TERMS.CIP), value: DELIVERY_TERMS.CIP},
        {text: localize(DELIVERY_TERMS.DAP), value: DELIVERY_TERMS.DAP},
        {text: localize(DELIVERY_TERMS.DPU), value: DELIVERY_TERMS.DPU},
        {text: localize(DELIVERY_TERMS.DDP), value: DELIVERY_TERMS.DDP},
      ];
    },
    statuses() {
      return [
        {
          name: localize(STATUSES.NEW),
          value: STATUSES.NEW
        },
        {
          name: localize(STATUSES.NEED_RESPONSE),
          value: STATUSES.NEED_RESPONSE
        },
        {
          name: localize(STATUSES.PENDING),
          value: STATUSES.PENDING
        },
        {
          name: localize(STATUSES.DRAFT),
          value: STATUSES.DRAFT
        },
        {
          name: localize(STATUSES.BOOKED),
          value: STATUSES.BOOKED
        },
        {
          name: localize(STATUSES.NOT_BOOKED),
          value: STATUSES.NOT_BOOKED
        },
        {
          name: localize(STATUSES.CLOSED),
          value: STATUSES.CLOSED
        },
        {
          name: localize(STATUSES.REJECTED),
          value: STATUSES.REJECTED
        },
        {
          name: localize(STATUSES.DECLINED),
          value: STATUSES.DECLINED
        },
        {
          name: localize(STATUSES.VIEWED),
          value: STATUSES.VIEWED
        },
        {
          name: localize(STATUSES.CANCELLED),
          value: STATUSES.CANCELLED
        },
        {
          name: localize(STATUSES.EXPIRED),
          value: STATUSES.EXPIRED
        },
        {
          name: localize(STATUSES.DELETED),
          value: STATUSES.DELETED
        },
      ]
    },
    countryPresets() {
      return [
        {
          key: PRESETS.DOMESTIC,
          name: localize('domestic'),
          description: localize('domesticNote')
        },
        {
          key: PRESETS.CROSS,
          name: localize('cross'),
          description: localize('crossNote')
        },
        {
          key: PRESETS.IMPORT,
          name: localize('import'),
          description: localize('importNote')
        },
        {
          key: PRESETS.IMPORT_EUROPE,
          name: localize('importEurope'),
          description: localize('importEuropeNote')
        },
        {
          key: PRESETS.EXPORT,
          name: localize('export'),
          description: localize('exportNote')
        },
        {
          key: PRESETS.EXPORT_EUROPE,
          name: localize('exportEurope'),
          description: localize('exportEuropeNote')
        },
        {
          key: PRESETS.ALL,
          name: localize('all'),
          description: localize('anyNote')
        }
      ]
    }
  },
  mutations: {
    changeLocale(state, payload) {
      state.locale = payload;
    },
    changeApiLocales(state, payload) {
      state.apiLocales = payload;
    },
    changeApiSettings(state, payload) {
      state.apiSettings = payload;
    },
    changeUserData(state, payload) {
      state.userData = payload;
    },
    changeUserName(state, payload) {
      state.userData.name = payload;
    },
    changeUserEmail(state, payload) {
      state.userData.email = payload;
    },
    changeUserBookingEmail(state, payload) {
      state.userData.booking_email = payload;
    },
    changeGlobalSnackbar(state, payload) {
      state.globalSnackbar = payload;
    },
    changeGlobalSnackbarColor(state, payload) {
      state.globalSnackbarColor = payload;
    },
    changeGlobalSnackbarText(state, payload) {
      state.globalSnackbarText = payload;
    },
  },
  actions: {},
  modules: {},
});
